<template>
  <div class="Contact">
    <div class="name">
        联系我们
    </div>
    <div class="collapse">
      <h3>可信大数据武汉有限公司</h3>
      <p>武汉地址：武汉市洪山区珞珈山路19号中科开物大厦12楼</p>
      <p>联系电话： 027-87638980 陶老师</p>
      <p>邮  箱：taowei@cnpkp.com</p>
      <p>邮  编：430072</p>
    </div>
      
  </div>
</template>

<script>
export default {
  name: 'Contact',
 components:{},
  data () {
    return {
    }
  },

  methods: {}
}
</script>

<style lang='scss' scoped>
.collapse{
  padding: 20px 0;
  h3{
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 10px;
  }
  p{
  color: rgba(0, 18, 22, 0.65);
  line-height:1.769230769230769;
  padding: 5px 0;
  }
}
.Contact {
    padding: 0 32px 0;
}
.name {
    font-size: 24px;
    font-weight: 600;
    color: rgba(0,18,22,.85);
    line-height: 36px;
    padding: 32px 0 32px 0;
    border-bottom: 1px solid #ebeef5;
}

</style>
